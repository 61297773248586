import React from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO, Image as Img, RecentPosts } from 'components';
import { Slices } from '../slices/slices';
import './blogpost.scss';

export const Blogpost = props => {
  const { location, data } = props;
  const { site } = data;
  const { siteName } = site.siteMetadata;
  const dataSlices = data && data.prismicBlogPost && data.prismicBlogPost.data && data.prismicBlogPost.data.body;
  console.log('data blogpost', data)
  const dataBlogPost = data && data.prismicBlogPost && data.prismicBlogPost.data;
  const title = `${dataBlogPost && dataBlogPost.title && dataBlogPost.title.text} | ${siteName}`;
  const category = ` Category | ${dataBlogPost && dataBlogPost.category}`;
  const datePublished = `Date published | ${dataBlogPost && dataBlogPost.date_published}`;
  const author = ` Author | ${dataBlogPost && dataBlogPost.author && dataBlogPost.author.text}`;
  const featuredImage = dataBlogPost && dataBlogPost.featured_image;
  return (
    <Layout location={location}>
      <SEO title={title} />
      <div className="blogpost">
        <div className="image-container blogpost-featured">
          <div className="image-box">
            <Img image={featuredImage} />
          </div>
        </div>
        <div className="wrapper">
          <h1 className="blogpost-title">{title}</h1>
          <div className="post-details">
            <p className="detail">{category}</p>
            <p className="detail">{datePublished}</p>
            <p className="detail">{author}</p>
          </div>
          <Slices data={dataSlices} />
          <RecentPosts />
        </div>
      </div>
    </Layout>
  );
};

export default Blogpost;

export const blogpostQuery = graphql`
  query($uid: String!) {
    site {
      siteMetadata {
        siteName
      }
    }
    prismicBlogPost(uid: { eq: $uid }) {
      data {
        title: post_title {
          text
        }
        featured_image {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        author {
          text
        }
        date_published
        category
        description: post_description {
          text
        }
        body {
          ...BlogPostWysiwyg
          ...BlogPostImage
        }
      }
    }
  }
`;
